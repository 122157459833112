import { Controller } from "stimulus";
import { useThrottle, useDispatch, useDebounce } from 'stimulus-use';

export default class extends Controller {
  static targets = ["mainWrapper", "optionsBlock"];

  static throttles = ['handleScroll'];

  static debounces = ['handleScrollEnd'];

  connect() {
    useThrottle(this, { wait: 100 });
    useDebounce(this, { wait: 100 });
    useDispatch(this);

    this.scrollDelta = 60;
    this.prevScrollPosition = 0;
    this.isSearchbarMiniMode = false;
  }

  handleScroll() {
    this.adjustSearchbarAppearance();
  }

  handleScrollEnd() {
    this.adjustSearchbarAppearance();
  }

  adjustSearchbarAppearance() {
    const wrapperHeight = this.getWrapperHeight();

    if (window.scrollY > wrapperHeight) {
      this.handleSearchbarMiniMode();
    } else {
      this.handleSearchbarNormalMode();
    }
  }

  getWrapperHeight() {
    return this.mainWrapperTarget.getBoundingClientRect().height;
  }

  setSearchbarMiniModeFlag() {
    this.isSearchbarMiniMode = true;
  }

  resetSearchbarMiniModeFlag() {
    this.isSearchbarMiniMode = false;
    // memorize window sroll position to switch searchbar to mini mode on scroll
    this.prevScrollPosition = window.scrollY;
  }

  handleSearchbarMiniMode() {
    if (this.isSearchbarMiniMode) return;

    if (Math.abs(window.scrollY - this.prevScrollPosition) > this.scrollDelta) {
      this.optionsBlockTarget.classList.add('fixed');
      this.dispatch('searchbarMini');
    }
  }

  handleSearchbarNormalMode() {
    if (!this.isSearchbarMiniMode) return;

    this.optionsBlockTarget.classList.remove('fixed');
    this.dispatch('searchbarNormal');
  }
}
