import { Controller } from "stimulus";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import Glide from "@glidejs/glide";
import { useDispatch } from "stimulus-use";
import { markerIconImage, markerActiveIconImage, markerClusterCircleImage } from "./resort_map_icons"
import { getCookie } from "../../init/cookie";

export default class extends Controller {

  static targets = ["map", "list"]

  connect() {
    useDispatch(this)
    this.locations = JSON.parse(this.data.get("locations"));
    this.loadGoogleMapsApi()
    this.averageLocation = this.calculateAverageLatLng(this.locations);

    this.setDatesVariables()
  }

  setDatesVariables() {
    // get dates from url parameters
    const urlParams = new URLSearchParams(window.location.search);
    this.checkin = urlParams.get("checkin");
    this.checkout = urlParams.get("checkout");

    if (this.checkin && this.checkout) return;

    // get dates from cookies if url parameters doesn't contain dates
    const cookies = JSON.parse(getCookie("filter_by_dates") || "{}");
    this.checkin = cookies.checkin;
    this.checkout = cookies.checkout;
  }

  switchListMap(e) {
    if (e.detail.toogle === 'map') {
      this.listTarget.classList.remove('show');
      this.mapTarget.classList.add('show');
    } else {
      this.listTarget.classList.add('show');
      this.mapTarget.classList.remove('show');
    }
  }

  loadGoogleMapsApi() {
    if (typeof google === 'undefined') {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDSCH4Yo01VrHO5czuK7c0nS6L0km6iAWE&libraries=places&callback=initMap`;
      script.async = true;
      script.defer = true;
      window.initMap = this.initMap.bind(this);
      document.head.appendChild(script);
    } else {
      this.initMap();
    }
  }


  calculateAverageLatLng(locations) {
    let totalLat = 0;
    let totalLng = 0;

    locations.forEach((location) => {
        totalLat += location.location.lat;
        totalLng += location.location.lng;
    });

    const avgLat = totalLat / locations.length;
    const avgLng = totalLng / locations.length;

    return { lat: avgLat, lng: avgLng };
  }

  initMap() {
    const myLatLng = { lat: 28.394402, lng: -81.500254 }; // Florida coordinates
    const mapItem = document.getElementById("resorts-map");
    const map = new google.maps.Map(mapItem, {
      zoom: 10,
      center: this.averageLocation,
      mapId: "eebff3fa081bbf42",
      clickableIcons: false,
    });
    const mediaQuery = window.matchMedia('(min-width: 1024px)')
    const markerIcon = {
      url: markerIconImage,
      scaledSize: new google.maps.Size(48, 61),
    };

    const markerActiveIcon = {
      url: markerActiveIconImage,
      scaledSize: new google.maps.Size(48, 61),
    };

    const markerCircleIcon = {
      url: markerClusterCircleImage,
      scaledSize: new google.maps.Size(60, 60),
    };

    let realOffset;
    const calculateOffset = () => {
      const defaultOffset = { x: 0, y: 400 };
      const mediaQueryOffset = { x: 320, y: 400 };

      realOffset = mediaQuery.matches ? mediaQueryOffset : defaultOffset;

      return realOffset;
    };

    calculateOffset();

    const infoWindow = new google.maps.InfoWindow({
      disableAutoPan: true,
      maxWidth: 327,
      minWidth: 327,
      pixelOffset: new google.maps.Size(realOffset.x, realOffset.y),
    });
    const markers = this.locations.map((position, i) => {
      const marker = new google.maps.Marker({
        position: position.location,
        icon: markerIcon,
      });

      google.maps.event.addListener(map, "click", function() {
        infoWindow.close();
        marker.setIcon(markerIcon)
      });

      const cardID = document.getElementById(`template-${position.id}`);
      const markerPosition = marker.getPosition();

      infoWindow.addListener("closeclick", () => {
        marker.setIcon(markerIcon)
      })

      marker.addListener("click", () => {
        markers.forEach(itemMarker => {
          itemMarker.setIcon(markerIcon);
        })
        infoWindow.close();
        infoWindow.setContent(cardID.innerHTML);
        infoWindow.open({anchor: marker, map});
        marker.getMap().panTo(markerPosition);
        marker.setIcon(markerActiveIcon);

        const listener = google.maps.event.addListener(infoWindow, 'domready', () => {
          const slider = mapItem.querySelector(".resort-slider");
          const bookNowBtn = mapItem.querySelector(".book-now");
    
          const smoothScroll = (() => {
            const element = document.getElementById("search-bar");
            if (element) {
              window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth'
              });
            }
          })
    
          const goToProperty = (() => {
            const bookUrl = bookNowBtn.getAttribute("data-url");
            if (this.checkin && this.checkout && bookUrl) {
              window.location.href = `${bookUrl}?checkin=${this.checkin}&checkout=${this.checkout}`;
            } else if (bookUrl) {
              window.location.href = `${bookUrl}`;
            }
          })
    
          const handleBookNowBtnClick = (() => {
            if (!this.checkin  || !this.checkout) {
              smoothScroll();
              this.dispatch("openCalendar");
            } else {
              goToProperty();
            }
          })
    
          this.glide = new Glide(slider, { type: 'slider', perView: 1, startAt: 0, swipeThreshold: 5, gap: 0 }).mount();
          bookNowBtn.addEventListener("click", () => {
            handleBookNowBtnClick()
          })
    
          google.maps.event.removeListener(listener);
    
        })
      });

      return marker;
    });

    const renderer = {
      render: ({ count, position }) =>
        new google.maps.Marker({
          label: { text: `0${String(count)}`, color: "white", fontSize: "14px" },
          icon: markerCircleIcon,
          position,
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        }),
    };
    const markerClusters = new MarkerClusterer({map, markers, renderer});
  }
}
