import { Controller } from "stimulus";
import Glide from "@glidejs/glide";
import MicroModal from 'micromodal';
import cart from "util/cart.js";
import { useDispatch } from 'stimulus-use';
import { markerIconImage, markerActiveIconImage } from "./pick_unit_map_icons"
import { getCookie } from "../../init/cookie";


export default class extends Controller {

  connect() {
    useDispatch(this, {debug: true});
    this.locations = JSON.parse(this.data.get("locations"));
    this.pageUnitTypeId = this.data.get("page-unit-type-id");
    this.setDatesVariables();
    this.setGuestsVariables()
    this.checkItemsPresenceInCart();
    this.averageLocation = this.calculateAverageLatLng(this.locations);

    this.loadGoogleMapsApi();
  }

  setDatesVariables() {
    // get dates from url parameters
    const urlParams = new URLSearchParams(window.location.search);
    this.checkin = urlParams.get('checkin');
    this.checkout = urlParams.get('checkout');
    if (this.checkin && this.checkout) return;
    // get dates from cookies if url parameters doesn't contain dates
    const cookies = JSON.parse(getCookie("filter_by_dates") || "{}");
    this.checkin = cookies.checkin;
    this.checkout = cookies.checkout;
  }

  setGuestsVariables() {
    // get guests from url parameters
    const urlParams = new URLSearchParams(window.location.search);
    this.adults = urlParams.get('adults') || 0;
    this.children = urlParams.get('children') || 0;

    if (this.adults || this.children) return;

    // get guests from cookies if url parameters doesn't contain them
    const cookies = JSON.parse(getCookie("filter_by_guests") || "{}");
    this.adults = cookies.adults || 0;
    this.children = cookies.children || 0;
  }

  

  checkItemsPresenceInCart() {
    this.cartItemsOfPageUnitType = cart.items().filter(item => item.unit_type_id === this.pageUnitTypeId);
    const selectUnitBtns = document.querySelector("#units-map .button-call-select-unit-class");
    if (selectUnitBtns) {
      const selectedBtnID = this.cartItemsOfPageUnitType.find(item => selectUnitBtns.dataset.unit === item.unit_id);
      if (selectedBtnID) {
        selectUnitBtns.classList.add("selected");
        selectUnitBtns.textContent = "In Cart";
      } else {
        selectUnitBtns.classList.remove("selected");
        selectUnitBtns.textContent = "Add to Cart";
      }
    }
  }

  calculateAverageLatLng(locations) {
    let totalLat = 0;
    let totalLng = 0;

    locations.forEach((location) => {
        totalLat += location.location.lat;
        totalLng += location.location.lng;
    });

    const avgLat = totalLat / locations.length;
    const avgLng = totalLng / locations.length;

    return { lat: avgLat, lng: avgLng };
  }

  loadGoogleMapsApi() {
    if (typeof google === 'undefined') {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDSCH4Yo01VrHO5czuK7c0nS6L0km6iAWE&libraries=places&callback=initMap`;
      script.async = true;
      script.defer = true;
      window.initMap = this.initMap.bind(this);
      document.head.appendChild(script);
    } else {
      this.initMap();
    }
  }

  

  initMap() {

    const myLatLng = { lat: 28.46158232, lng: -81.44954046 }; // take from Track?
    const mapItem = document.getElementById("units-map");
    const map = new google.maps.Map(mapItem, {
      zoom: 17,
      center: this.averageLocation,
      mapId: "3e0f6154bd45ceee",
      clickableIcons: false,
    });
    const mediaQuery = window.matchMedia('(max-width: 1200px)')

    const markerIcon = {
      url: markerIconImage,
      scaledSize: new google.maps.Size(48, 61),
    };

    const markerActiveIcon = {
      url: markerActiveIconImage,
      scaledSize: new google.maps.Size(48, 61),
    };

    const infoWindowArray = [];
    const markers = this.locations.map((position, i) => {
      const marker = new google.maps.Marker({
        position: position.location,
        icon: markerIcon,
        map,
      });

      const infoWindow = new google.maps.InfoWindow({
        disableAutoPan: true,
        maxWidth: 340,
        minWidth: 340,
        pixelOffset: new google.maps.Size(0, 250),
      });

      infoWindowArray.push(infoWindow)

      if (mediaQuery.matches) {
        google.maps.event.addListener(map, "click", function() {
          infoWindowArray.forEach((infoWindowItem)=> {
            infoWindowItem.close();
          });
          marker.setIcon(markerIcon)
        });

        const cardID = document.getElementById(`pick-unit-${position.id}`);
        const markerPosition = marker.getPosition();
        infoWindow.setContent(cardID.innerHTML);

        infoWindow.addListener("closeclick", () => {
          marker.setIcon(markerIcon)
        })

        marker.addListener("click", (_, index) => {
          markers.forEach(itemMarker => {
            itemMarker.setIcon(markerIcon);
          })
          infoWindowArray.forEach((infoWindowItem)=> {
            infoWindowItem.close();
          })

          infoWindow.open({anchor: marker, map});
          marker.getMap().panTo(markerPosition);
          marker.setIcon(markerActiveIcon);

          const listener = google.maps.event.addListener(infoWindow, 'domready', () => {
            const totalPriceDropdownButton = mapItem.querySelector(".total-price");
            const priceBlockContainer = mapItem.querySelector(".price-block");
            const totalPriceBlock = mapItem.querySelector(".total-price-price");
            const totalDropdownBlock = mapItem.querySelector(".dropdown-hidden-info");
            const slider = mapItem.querySelector(".pick-unit-card-slider");
            const sliderCallModalBtn = mapItem.querySelector(".button-call-modal-slider");
            const selectUnitBtn = mapItem.querySelector(".button-call-select-unit");
            const disableBlock = mapItem.querySelector(".disable-card");
            const errorMessage = mapItem.querySelector(".error-message");
            const bookBtn = mapItem.querySelector(".button_to");


            totalPriceDropdownButton.addEventListener('click', () => {
              totalPriceDropdownButton.classList.toggle("open");
            })
            this.glide = new Glide(slider, { type: 'slider', perView: 1, startAt: 0, swipeThreshold: 5, gap: 0 }).mount();
            sliderCallModalBtn.addEventListener('click', () => {
              MicroModal.show(sliderCallModalBtn.getAttribute('data-micromodal-trigger'));
              this.dispatch(`modal-opened-pick-unit-modal-slider-${position.id}`);
            })

            const bookingItemParams =  {
              unit_id: selectUnitBtn.dataset.unit,
              type: 'unit',
              arrival_date: this.checkin,
              departure_date: this.checkout,
              unit_type_id: this.pageUnitTypeId,
              adults: this.adults,
              children: this.children,
            }

           

          const setUpdateCartButtonState = () => {
            selectUnitBtn.classList.add("selected");
            selectUnitBtn.textContent = "Update";
          }
        
          const setSelectedCartButtonState = () => {
            selectUnitBtn.classList.add("selected");
            selectUnitBtn.textContent = "In Cart";
          }
        
          const setUnselectedCartButtonState = () => {
            selectUnitBtn.classList.remove("selected");
            selectUnitBtn.textContent = "Add to Cart";
          }

          const updateCartButtonState = (total) => {
            const unitId = selectUnitBtn.dataset.unit
            const bookigItemFromCart = cart.item("unit", unitId);
            bookingItemParams.total = total;

            if (bookigItemFromCart && JSON.stringify(bookigItemFromCart) === JSON.stringify(bookingItemParams)) {
              setSelectedCartButtonState();
              this.dispatch(`updateButtonState-${unitId}`, {buttonState: "inCart"});
            } else if (bookigItemFromCart) {
              setUpdateCartButtonState();
              this.dispatch(`updateButtonState-${unitId}`, {buttonState: "updateCart"});
              this.dispatch(`bookigItemFromCart-${unitId}`, { bookingItemParams })
            } else {
              setUnselectedCartButtonState();
              this.dispatch(`updateButtonState-${unitId}`, {buttonState: "addToCart"});
            }
          }

          if (totalDropdownBlock.hasChildNodes()) {
            bookingItemParams.total = parseFloat(totalPriceBlock.textContent.replace(/[^\d.-]/g, ''));
            // updateCartButtonState(bookingItemParams.total)
          }

            bookBtn.addEventListener('click', (event) => {
              event.preventDefault();
              const unitId = selectUnitBtn.dataset.unit;
              const isItemPresentInCart = cart.item("unit", selectUnitBtn.dataset.unit);
              if (!isItemPresentInCart && this.checkin && this.checkout && parseInt(this.adults, 10)) {
                this.dispatch(`selectedUnit-${unitId}`, { unitId, total: this.total });
                this.dispatch(`saveTotal-${unitId}`, { unitId, total: this.total });
                bookBtn.submit();
              } else if (isItemPresentInCart && this.checkin && this.checkout && parseInt(this.adults, 10)) {
                cart.update("unit", unitId, bookingItemParams);
                this.dispatch(`updateButtonState-${unitId}`, {buttonState: "updateCart"});
                this.dispatch(`updateInCart-${unitId}`, {bookingItemParams});
                bookBtn.submit();
              }
            })


            selectUnitBtn.addEventListener('click', (event) => {
              event.preventDefault();
              const unitId = event.target.dataset.unit;
              const isItemPresentInCart = cart.item("unit", unitId);
              if (!isItemPresentInCart && this.checkin && this.checkout && parseInt(this.adults, 10)) {
                this.dispatch(`selectedUnit-${unitId}`, { unitId, total: this.total });
                this.dispatch(`saveTotal-${unitId}`, { unitId, total: this.total });
                this.dispatch(`updateButtonState-${unitId}`, {
                  buttonState: "inCart"
                });
                setSelectedCartButtonState();
              }
              if (isItemPresentInCart && !(JSON.stringify(isItemPresentInCart) === JSON.stringify(bookingItemParams)) && this.checkin && this.checkout && parseInt(this.adults, 10)) {
                cart.update("unit", unitId, bookingItemParams);
                setUpdateCartButtonState();
                this.dispatch(`updateButtonState-${unitId}`, {buttonState: "updateCart"});
                // window.dispatchEvent(new CustomEvent("cartUpdated"));
                // window.dispatchEvent(new CustomEvent(`cartUpdated-${unitId}`));
                this.dispatch(`updateInCart-${unitId}`, {bookingItemParams});
              }
            })


            // const selectedBtnID = this.cartItemsOfPageUnitType.find(item => selectUnitBtn.dataset.unit === item.unit_id);
            // if (selectedBtnID) {
            //   selectUnitBtn.classList.add("selected");
            //   selectUnitBtn.textContent = "In Cart";
            // } else {
            //   selectUnitBtn.classList.remove("selected");
            //   selectUnitBtn.textContent = "Add to Cart";
            // }

            const updateTotalDropdown = (dropdownItems) => {
              totalDropdownBlock.innerHTML = "";
              dropdownItems.forEach(item => {
                const itemPrice = parseFloat(item.value);
                const itemHtml = `
                  <div class="dropdown-row">
                    <div class="dropdown-row-text">${item.name}</div>
                    <div class="dropdown-row-text">$${itemPrice.toLocaleString('en-US')}</div>
                  </div>
                `;
                totalDropdownBlock.insertAdjacentHTML('beforeend', itemHtml);
              });
            }
          
            const updateTotal = (totalPrice) => {
              totalPriceBlock.textContent = `$${totalPrice.toLocaleString('en-US')}`;
            }

            

            const handleQuoteSuccessfulResponse = (response) => {
              const {
                guest_fees: feeItems,
                total_taxes: taxes,
                total
              } = response;
              const totalDropdownItems = [...feeItems];
              const totalWithoutTaxes = total - taxes;

              this.total = total;
              updateTotalDropdown(totalDropdownItems);
              updateTotal(totalWithoutTaxes);
              bookingItemParams.total = total;
            }

            const handleQuoteResponse = (response) => {
              priceBlockContainer.classList.remove("loading");
              if (response.status === "error") {
                disableBlock.classList.add('active');
                errorMessage.style.display = "block";
                slider.style.opacity = "0.5";
                slider.style.pointerEvents = "none";
                priceBlockContainer.style.display = "none";
              } else {
                handleQuoteSuccessfulResponse(response);
                updateCartButtonState(response.total);
              }
            }
           
            const sendQuoteRequest = (unitId) => {
              if (!this.checkin || !this.checkout) return;
              const data = {
                unit_id: unitId,
                type: 'unit',
                arrival_date: this.checkin,
                departure_date: this.checkout
              };
              priceBlockContainer.classList.add("loading");

              fetch("/api/quote.json", {
                method: 'POST',
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
              }).then(response => response.json())
                .then(result => {
                  handleQuoteResponse(result)})
            }

            // totalDropdownBlock must be empty to sent a request
            if (!totalDropdownBlock.hasChildNodes()) {
              sendQuoteRequest(selectUnitBtn.dataset.unit)
            }

            google.maps.event.removeListener(listener);
          });
        });

      } else {
        const cardID = document.getElementById(`pick-unit-${position.id}`);
        const checkCardSelection = () => {
          const allCards = document.querySelectorAll('.unit-card');
          allCards.forEach(card => {
            card.style.border = "none";
            card.style.boxShadow = "none";
          })
        }
        const addStyleCardSelection = () => {
          cardID.style.border = "1px solid #122E58"
          cardID.style.boxShadow = "0px 4px 11px 0px rgba(0, 0, 0, 0.10)"
        }

        cardID.addEventListener("click", () => {
          markers.forEach(itemMarker => {
            itemMarker.setIcon(markerIcon);
          })
          checkCardSelection();
          addStyleCardSelection();
          const markerPosition = marker.getPosition();
          marker.getMap().panTo(markerPosition);
          marker.setIcon(markerActiveIcon);
        })

        marker.addListener("click", () => {
          const heightFiltersStickyBar = document.getElementById('sticky-desktop-filters-container').clientHeight;
          markers.forEach(itemMarker => {
            itemMarker.setIcon(markerIcon);
          })
          marker.setIcon(markerActiveIcon);
          checkCardSelection();
          const cardContainer = document.getElementById('units-wrapper');
          const topContainerPosition = cardContainer.offsetTop;
          const cardPosition = cardID.offsetTop - topContainerPosition - heightFiltersStickyBar;
          cardContainer.style.scrollBehavior = 'smooth';
          cardContainer.scrollTop = cardPosition;
          addStyleCardSelection();
        });
      }

      return marker;
    });

  }
}
