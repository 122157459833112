import { Controller } from "stimulus";
import MicroModal from 'micromodal';
import { useThrottle, useDispatch } from 'stimulus-use';

export default class extends Controller {
  static targets = ["map", "list", "searchFiltersBar", "searchWrapper"]

  static throttles = ['handleScroll']

  connect() {
    useThrottle(this, { wait: 100 });
    useDispatch(this);
    MicroModal.init({
      disableScroll: true,
      onShow: modal => this.dispatch(`modal-opened-${modal.id}`, { modalID: modal.id }),
    });

    this.scrollDeadZone = 60;
    this.prevScrollPositionForSearchbar = 0;
    this.absoluteSearchbarPosition = this.getAbsoluteSearchbarPosition();
  }

  setSearchbarMiniModeFlag() {
    this.isSearchbarMiniMode = true;
  }

  resetSearchbarMiniModeFlag() {
    this.isSearchbarMiniMode = false;
    // memorize window sroll position to switch searchbar to mini mode on scroll
    this.prevScrollPosition = window.scrollY;
  }

  handleScroll() {
    this.handleSearchbarModeOnScroll();
    this.adjustSubHeaderAndSearchbarOnScrollUp();
  }

  handleSearchbarModeOnScroll() {
    if (this.isSearchbarMiniMode
      || Math.abs(window.scrollY - this.prevScrollPosition) <= this.scrollDeadZone) return;

    this.dispatch('searchbarMini');
  }

  adjustSubHeaderAndSearchbarOnScrollUp() {
    if (window.scrollY < this.prevScrollPositionForHeader && window.scrollY > this.absoluteSearchbarPosition) {
      this.dispatchHeaderEvents("fixHeader");
      this.searchWrapperTarget.classList.add('under-header');
    } else {
      this.dispatchHeaderEvents("unfixHeader");
      this.searchWrapperTarget.classList.remove('under-header');
    }
    this.prevScrollPositionForHeader = window.scrollY;
  }

  switchListMap(e) {
    if (e.detail.toogle === 'map') {
      this.listTarget.classList.remove('show');
      this.mapTarget.classList.add('show');
    } else {
      this.listTarget.classList.add('show');
      this.mapTarget.classList.remove('show');
    }
  }

  getAbsoluteSearchbarPosition() {
    let absoluteY = 0;
    let currentElement = this.searchWrapperTarget;

    // Traverse the offset parent chain until body element is reached
    while (currentElement.offsetParent !== null) {
      // Add current element's offsetTop to the absoluteY value
      absoluteY += currentElement.offsetTop;
      // Move to the next offset parent
      currentElement = currentElement.offsetParent;
    }

    return absoluteY + this.searchWrapperTarget.offsetHeight;
  }

  dispatchHeaderEvents(event) {
    window.dispatchEvent(new CustomEvent(event));
  }

  // async loadMoreUnits(e) {
  //   const { currentPage } = e.detail;

  //   const url = new URL(window.location);
  //   url.searchParams.set("page", currentPage + 1);

  //   try {
  //     const response = await fetch(url, {
  //       headers: {
  //         "X-Requested-With": "XMLHttpRequest",
  //         "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
  //       },
  //     });

  //     const html = await response.text();
  //     const doc = new DOMParser().parseFromString(html, "text/html");
  //     const list = this.element.querySelector("div.listing-container");

  //     doc.querySelectorAll(".listing-container > *").forEach(item => {
  //       list.appendChild(item.cloneNode(true));
  //     });

  //     window.history.pushState({ page: currentPage + 1 }, "", url.toString());

  //     this.dispatch('updatePageNumber', { pageNumber: currentPage + 1 })

  //   } catch (error) {
  //     console.error("Error loading more items:", error);
  //   }
  // }

}
