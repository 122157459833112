import { setCookie, deleteCookie } from "init/cookie";
import unitQuote from "util/quote";
import {DateTime} from "luxon";


function getInitialOrderObject() {
    return {
        last_change: DateTime.now(),
        items: []
    };
}

function createOrder(items) {
    const orderDataObject = getInitialOrderObject();

    items.forEach(item => {
        orderDataObject.items.push(item);
    })

    saveOrder(orderDataObject)
}

function saveOrder(order) {
    localStorage.setItem("order", JSON.stringify(order));
    setCookie("order", JSON.stringify(order), 3);
}

function deleteOrder() {
    localStorage.removeItem("order");
    deleteCookie('order')
}

function getOrder() {
    const orderDataObject = localStorage.getItem("order");

    if (orderDataObject === null) {
        return null;
    }

    const order = JSON.parse(orderDataObject);
    const lastChange = DateTime.fromJSDate(order.last_change);
    if (lastChange.diffNow("hours") >= 4) {
        return resetOrder();
    }

    return order;
}

function resetOrder() {
    localStorage.removeItem("order");
    deleteCookie("order");
    return getInitialOrderObject();
}

function getOrderItems() {
    const order = getOrder();
    return order.items;
}

export function getOrderItem(unitType, unitId) {
    const order1 = getOrder();
    return order1.items.find(
        item => item.unit_id === unitId && item.type === unitType
    );
}

function removeFromOrder(unitType, unitId) {
    const order = getOrder();
    order.items = order.items.filter(
        item => (item.unit_id !== unitId || (item.unit_id === unitId && item.type !== unitType))
    );
    saveOrder(order);
}

async function getOrderItemWithQuote(unitType, unitId) {
    const item = getOrderItem(unitType, unitId);
    return {
        params: item,
        quote: await unitQuote(item)
    };
}

function updateOrderItem(unitType, unitId, params = {}) {
    const order = getOrder();
    order.items = order.items.map(item =>
        item.unit_id === unitId && item.type === unitType ? params : item
    );
    saveOrder(order);
}

const order = {
    create: createOrder,
    delete: deleteOrder,
    items: getOrderItems,
    clear: resetOrder,
    itemWithQuote: getOrderItemWithQuote,
    updateItem: updateOrderItem,
    removeItem: removeFromOrder
}

window.vltorder = order
export default order
