// This can be removed if we drop IE11 support
import fetch from "unfetch";

import { formParams } from "./forms";

export function fetchWithCSRF(url, options = {}) {
  options.headers || (options.headers = {});

  const token = csrfToken();
  if (token) {
    options.headers["X-CSRF-Token"] = token;
  }

  return fetch(url, options);
}

function csrfToken() {
  const meta = document.querySelector("meta[name=csrf-token]");
  return meta && meta.content;
}

// Submit a form via AJAX and return HTML
export async function submitForm(form) {
  const formData = formParams(form);
  const response = await fetchWithCSRF(form.action, {
    method: "POST",
    body: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
    }
  });
  const json = await response.json();

  let result;
  if (json.redirect_to) {
    window.location.href = json.redirect_to;
    result = document.createRange().createContextualFragment("");
  } else if (json.html) {
    result = document.createRange().createContextualFragment(json.html);
  } else {
    result = json;
  }

  return result;
}

export function getRequest(uri, callback) {
  let oReq = new XMLHttpRequest();
  oReq.addEventListener("load", callback);
  oReq.open("GET", uri);
  oReq.send();
}

export function postJson(uri, data, callback) {
  let xhttp = new XMLHttpRequest();
  let csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  xhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
      if (this.responseText !== "null") {
        callback(JSON.parse(this.responseText));
      }
    }
  };
  if (uri.indexOf("http") != -1) {
    let location = window.location;
    let baseUrl = `${location.protocol}//${location.hostname}`;
    if (location.port.length == 4) baseUrl = `${baseUrl}:${location.port}`;
    uri = `${baseUrl}${uri}`;
  }
  xhttp.open("POST", uri, true);
  xhttp.setRequestHeader("X-CSRF-Token", csrfToken);
  xhttp.setRequestHeader("Content-Type", "application/json");
  xhttp.send(JSON.stringify(data));
}
